<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 12L12 8L8 12" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 16V8" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
  
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        iconColor: {
            type: String,
            default: "#F89520",
        },
    },
};
</script>