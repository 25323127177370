<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 flex flex-col justify-center items-center"
        >
          <div
            class="w-10/12 modal-body text-center mt-5 mb-0 font-proximaMedium"
          >
            <h3 class="text-base">Do you want to sign out of your account?</h3>
          </div>

          <div
            class="modal-footer w-full flex flex-col md:flex-row justify-center items-center"
          >
            <BorderedButton
              class="md:mr-1"
              :title="$t('global_cancel')"
              borderColor="border-mediumGrey"
              textCl="text-darkGrey"
              paddingX="px-10"
              width="w-11/12 md:w-4/12"
              @click.native="$emit('close')"
            />
            <SubmitButton
              class="md:ml-1"
              :title="$t('global_yes')"
              background="bg-success"
              paddingX="px-10"
              width="w-11/12 md:w-4/12"
              @click.native="logout"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import TrashIcon from "../icons/TrashIcon.vue";
import CheckIcon from "../icons/CheckIcon.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

export default {
  props: {
    show: Boolean,
  },
  components: {
    TrashIcon,
    CheckIcon,
    SubmitButton,
    BorderedButton,
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("role");
      localStorage.removeItem("pet");
      localStorage.removeItem("firstpet");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
