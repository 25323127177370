<template>
  <ul class="mt-12">
    <!-- <NavEach
      :title="$t('account_dropdown_menu_settings')"
      component="SettingIcon"
      to=""
    /> -->
    <NavEach
      :title="$t('pet_record_title')"
      component="PawsIcon"
      to="GuestPetOverview"
    />
    <!-- <NavEach
      :title="$t('pet_dropdown_menu_shared_records')"
      component="PawsIcon"
    /> -->
    <!--  <NavEach :title="$t('global_header_services')" component="ServiceIcon" /> -->
    <NavEachUpgrade title="Upgrade" component="UpgradeIcon" />
    <!-- <NavEach :title="$t('global_upgrade')" component="VetIcon" /> -->
    <!-- <NavEach :title="$t('global_sign_out')" component="SignoutIcon" /> -->
  </ul>
</template>

<script>
import NavEach from "./NavEach.vue";
import NavEachUpgrade from "./NavEachUpgrade.vue";
import ModalsLogout from "../modals/ModalsLogout.vue";

export default {
  props: { user: {} },
  components: {
    NavEach,
    ModalsLogout,
    NavEachUpgrade,
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style></style>
