<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 8.67631C18.3848 8.67631 22.75 7.21958 22.75 5.42263C22.75 3.62567 18.3848 2.16895 13 2.16895C7.61522 2.16895 3.25 3.62567 3.25 5.42263C3.25 7.21958 7.61522 8.67631 13 8.67631Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.75 13.0146C22.75 14.815 18.4167 16.2683 13 16.2683C7.58333 16.2683 3.25 14.815 3.25 13.0146"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.25 5.42285V20.6067C3.25 22.4071 7.58333 23.8604 13 23.8604C18.4167 23.8604 22.75 22.4071 22.75 20.6067V5.42285"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>