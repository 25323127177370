<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 33 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_32412_3709)">
      <path
        d="M1.75655 1.00569H13.0272C14.1509 1.00569 15.1647 1.71675 15.6768 2.8639C15.7492 3.02502 15.8604 3.15474 15.9974 3.24892V22.2274C15.2567 21.2945 14.1917 20.7383 13.0272 20.7383L1.75655 20.7404V1.00569V1.00569ZM17.3201 2.86597C17.8301 1.71882 18.8464 1.00776 19.9697 1.00776H31.2404V20.7425L19.9697 20.7404C18.8069 20.7404 17.7402 21.2962 16.9995 22.2294V3.25099C17.1365 3.15681 17.2477 3.02709 17.3201 2.86597V2.86597ZM32.2432 0.954632V0.503019C32.2432 0.224254 32.0205 0 31.7428 0H19.97C18.5124 0 17.201 0.860446 16.4998 2.25876C15.7983 0.860446 14.4869 0 13.0293 0H1.25649C0.979179 0 0.756092 0.223909 0.756092 0.503019V0.954632H0V23.4059H12.7424C14.2137 23.4059 15.4877 24.3568 16.1051 25.7395C16.2613 26.0873 16.7384 26.0873 16.8945 25.7395C17.5099 24.3547 18.7839 23.4059 20.2573 23.4059H32.9997V0.954632H32.2429H32.2432Z"
        :fill="iconColor"
      />
      <path
        d="M20.5126 12.7815L20.5147 12.7794L27.2502 6.0835L28.057 6.88495L21.3274 13.5753L19.3989 14.6911L20.5126 12.7815V12.7815ZM28.4383 4.90082L29.2452 5.70227L28.7685 6.17562L27.9616 5.37417L28.4383 4.90082ZM19.0354 15.9141C19.1978 15.9141 19.3635 15.871 19.518 15.7806L21.8257 14.4468C21.904 14.4016 21.976 14.3467 22.0368 14.2857L29.9859 6.38159C30.1753 6.19287 30.2828 5.93757 30.2828 5.68054C30.2828 5.4449 30.1928 5.22685 30.0308 5.06574L29.0637 4.10903C28.7177 3.76541 28.1356 3.7868 27.7643 4.1563L26.8929 5.02261L19.8135 12.0645C19.749 12.1273 19.6944 12.198 19.6494 12.2767L18.3147 14.561C18.0978 14.9322 18.1427 15.3742 18.4262 15.6609C18.5903 15.8279 18.8092 15.9141 19.0358 15.9141H19.0354Z"
        :fill="iconColor"
      />
    </g>
    <!-- <defs>
      <clipPath id="clip0_32412_3709">
        <rect :width="width" :height="height" :fill="iconColor" />
      </clipPath>
    </defs> -->
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>