<template>
  <div>
    <div
      class="w-max absolute top-5 md:top-12 right-5 z-20 cursor-pointer"
      @click="showMenu = !showMenu"
    >
      <img
        class="rounded-full h-12 w-12"
        rel="prefetch"
        :src="profileImageComp"
        alt="profile picture"
      />
    </div>
    <Transition>
      <div
        class="w-full h-full bg-lightGrey40 fixed left-0 top-0 z-10"
        v-if="showMenu"
      >
        <img
          class="absolute left-4 top-20 z-30 cursor-pointer"
          rel="prefetch"
          src="@/assets/images/alignleft-icon.png"
          alt="alignleft-icon"
          @click="showMenu = !showMenu"
        />
        <div
          class="selectpets h-5/6 w-10/12 md:w-4/12 lg:w-3/12 py-4 bg-primary-20 shadow-md rounded-r-2xl fixed md:absolute top-16 md:top-16 left-0 md:left-0 z-20"
          style="
            display: flex;
            flex-direction: column;
            background-color: #e9fffd;
          "
        >
          <div
            class="flex flex-col justify-center items-center relative"
            style="flex-shrink: 0"
          >
            <img
              rel="prefetch"
              :src="profileImageComp"
              class="rounded-full w-20 h-20 mt-10 my-4"
              alt="profile picture"
            />
            <h3 class="text-lg font-proximaMedium">
              {{ this.user.firstName + " " + this.user.lastName }}
            </h3>
            <h5 class="text-lg font-proximaLight capitalize">
              {{ this.getRoleNme() }}
            </h5>

            <!--  <div
              class="w-full absolute mt-12 top-15 -left-32 justify-center items-center"
            > -->
            <div
              class="w-1/4 absolute mt-12 top-15 left-5 justify-center items-center"
            >
              <div class="mt-15" style="flex-shrink: 0">
                <NavCompLogout
                  component="SignoutIcon"
                  :height="40"
                  :width="40"
                  @click.native="showModal = true"
                />
              </div>
              <ModalsLogout :show="showModal" @close="showModal = false" />
            </div>
          </div>
          <div class="absolute right-4 top-5 z-30 cursor-pointer">
            <NavComp
              component="SettingIcon"
              iconColor="#0AC0CB"
              :height="25"
              :width="25"
              :to="goToSettingAccount"
              :data="user"
            />
            <NavComp
              v-if="role != 4 && role != 2"
              component="ContactIcon"
              iconColor="#0AC0CB"
              :height="25"
              :width="25"
              to="Contacts"
              :data="user"
            />
            <NavComp
              v-if="role == 6"
              component="BellIcon"
              :height="25"
              :width="25"
              to=""
              :data="user"
            />
          </div>
          <NavMenuDogbreeder
            v-show="existPets"
            v-if="this.role == 3"
            :user="user"
          />
          <NavMenuPetowner
            v-show="existPets"
            v-if="this.role == 1"
            :user="user"
          />
          <NavMenuGuest v-if="role == 2" :user="user" />
          <NavMenuProvider
            v-show="existPets"
            v-if="this.role == 4"
            :user="user"
          />
          <NavMenuShelter
            v-show="existPets"
            v-if="this.role == 6"
            :user="user"
          />
          <NavMenuVeterinarian
            v-show="existPets"
            v-if="this.role == 5"
            :user="user"
          />
          <br />
          <!-- <div
            class="w-full absolute mt-12 bottom-10 justify-center items-center"
          >
            <div class="mt-15" style="flex-shrink: 0">
              <NavComp
                component="SignoutIcon"
                :height="40"
                :width="40"
                @click.native="showModal = true"
              />
            </div>
            <ModalsLogout :show="showModal" @close="showModal = false" />
          </div> -->
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import NavEach from "./NavEach.vue";
import NavCompLogout from "./NavCompLogout.vue";
import NavComp from "./NavComp.vue";
import ModalsLogout from "../modals/ModalsLogout.vue";
import NavMenuDogbreeder from "./NavMenuDogbreeder.vue";
import NavMenuGuest from "./NavMenuGuest.vue";
import NavMenuPetowner from "./NavMenuPetowner.vue";
import NavMenuProvider from "./NavMenuProvider.vue";
import NavMenuShelter from "./NavMenuShelter.vue";
import NavMenuVeterinarian from "./NavMenuVeterinarian.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    user: {},
    role: String,
  },
  components: {
    NavEach,
    NavCompLogout,
    NavComp,
    NavMenuDogbreeder,
    NavMenuGuest,
    NavMenuPetowner,
    NavMenuProvider,
    NavMenuShelter,
    NavMenuVeterinarian,
    ModalsLogout,
  },
  data() {
    return {
      showModal: false,
      showMenu: false,
      whichHover: false,
      email: "",
    };
  },
  mounted() {
    /* this.email = localStorage.getItem("email");
    this.role = localStorage.getItem("role");*/
    console.log("nnnnnnnnnn", this.email, this.role);
  },
  methods: {
    mouseover(i) {
      this.whichHover = true;
    },
    mouseleave(i) {
      this.whichHover = false;
    },
    getRoleNme() {
      if (this.role == 1) return "Pet Owner";
      if (this.role == 2) return "Guest";
      if (this.role == 3) return "Dog Breeder";
      if (this.role == 4) return "Service Provider";
      if (this.role == 5) return "Veterinarian";
      if (this.role == 6) return "Shelter / Rescue";
    },
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
    }),
    existPets() {
      return this.petRecords.length > 0;
    },
    profileImageComp() {
      return this.user
        ? this.user.profileImage
          ? this.user.profileImage
          : require(`@/assets/images/profilepic-icon.png`)
        : require(`@/assets/images/profilepic-icon.png`);
    },
    hoverComp() {
      if (this.whichHover) {
        return "#fff";
      } else {
        return "#0AC0CB";
      }
    },
    goToSettingAccount() {
      if (this.role == 1 || this.role == 3 || this.role == 2) return "Account";
      // if (this.role == 2) return "Guest";
      //if (this.role == 3) return "DogBreedAccount";
      if (this.role == 4) return "ServiceProviderAccount";
      if (this.role == 5) return "VeterinarianAccount";
      if (this.role == 6) return "ShelterAccount";
    },
  },
};
</script>

<style>
#toggle {
  display: block;
  width: 28px;
  height: 30px;
  margin: 30px auto 5px;
  cursor: pointer;
}

#toggle span:after,
#toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -9px;
}
#toggle span:after {
  top: 9px;
}
#toggle span {
  position: relative;
  display: block;
}

#toggle span,
#toggle span:after,
#toggle span:before {
  width: 100%;
  height: 3px;
  background-color: #888;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px;
}

/* on activation */
#toggle.on span {
  background-color: transparent;
}
#toggle.on span:before {
  transform: rotate(45deg) translate(5px, 5px);
  background-color: #f8961f;
}
#toggle.on span:after {
  transform: rotate(-45deg) translate(7px, -8px);
  background-color: #f8961f;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
