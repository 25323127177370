<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_38278_25032)">
      <path
        d="M31.5 -0.00063785H8.5C6.26838 -0.0206358 4.12 0.845607 2.5264 2.40796C0.932803 3.97031 0.0241921 6.10112 0 8.3327L0 13.3327C0 13.7747 0.175595 14.1986 0.488155 14.5112C0.800716 14.8238 1.22464 14.9994 1.66667 14.9994C2.10869 14.9994 2.53262 14.8238 2.84518 14.5112C3.15774 14.1986 3.33333 13.7747 3.33333 13.3327V8.3327C3.35701 6.98502 3.91426 5.70177 4.88285 4.76442C5.85144 3.82708 7.15228 3.31219 8.5 3.3327H31.5C32.8477 3.31219 34.1486 3.82708 35.1171 4.76442C36.0857 5.70177 36.643 6.98502 36.6667 8.3327V31.666C36.643 33.0137 36.0857 34.297 35.1171 35.2343C34.1486 36.1717 32.8477 36.6865 31.5 36.666H8.5C7.15228 36.6865 5.85144 36.1717 4.88285 35.2343C3.91426 34.297 3.35701 33.0137 3.33333 31.666V26.666C3.33333 26.224 3.15774 25.8001 2.84518 25.4875C2.53262 25.175 2.10869 24.9994 1.66667 24.9994C1.22464 24.9994 0.800716 25.175 0.488155 25.4875C0.175595 25.8001 0 26.224 0 26.666L0 31.666C0.0241921 33.8976 0.932803 36.0284 2.5264 37.5908C4.12 39.1531 6.26838 40.0194 8.5 39.9994H31.5C33.7316 40.0194 35.88 39.1531 37.4736 37.5908C39.0672 36.0284 39.9758 33.8976 40 31.666V8.3327C39.9758 6.10112 39.0672 3.97031 37.4736 2.40796C35.88 0.845607 33.7316 -0.0206358 31.5 -0.00063785V-0.00063785Z"
        :fill="iconColor"
      />
      <path
        d="M31.6665 19.9796C31.6665 20.4216 31.4987 20.8455 31.2 21.1581C30.9013 21.4706 30.4962 21.6462 30.0738 21.6462L9.06968 21.5962L15.9548 28.8012C16.1069 28.955 16.2283 29.1389 16.3117 29.3422C16.3952 29.5456 16.4391 29.7643 16.441 29.9856C16.4428 30.2069 16.4025 30.4263 16.3224 30.6311C16.2423 30.836 16.1241 31.0221 15.9745 31.1785C15.825 31.335 15.6472 31.4588 15.4514 31.5426C15.2557 31.6264 15.046 31.6686 14.8345 31.6666C14.623 31.6647 14.4141 31.6187 14.2197 31.5314C14.0254 31.444 13.8497 31.3171 13.7028 31.1579L6.39877 23.5146C5.50304 22.5769 4.99984 21.3054 4.99984 19.9796C4.99984 18.6537 5.50304 17.3822 6.39877 16.4446L13.6996 8.80123C14 8.49763 14.4023 8.32964 14.8199 8.33344C15.2375 8.33723 15.6369 8.51252 15.9322 8.82153C16.2275 9.13054 16.395 9.54856 16.3986 9.98556C16.4023 10.4226 16.2417 10.8436 15.9516 11.1579L9.16365 18.2629L30.0738 18.3129C30.4962 18.3129 30.9013 18.4885 31.2 18.801C31.4987 19.1136 31.6665 19.5375 31.6665 19.9796V19.9796Z"
        :fill="iconColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_38278_25032">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    iconColor: {
      type: String,
      default: "#FFAA54",
    },
  },
};
</script>