<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6665 8.00098H11.9165"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.1665 18.8467H5.4165"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.4165 22.1006C20.2114 22.1006 21.6665 20.6439 21.6665 18.8469C21.6665 17.05 20.2114 15.5933 18.4165 15.5933C16.6216 15.5933 15.1665 17.05 15.1665 18.8469C15.1665 20.6439 16.6216 22.1006 18.4165 22.1006Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5835 11.2549C9.37842 11.2549 10.8335 9.7982 10.8335 8.00124C10.8335 6.20428 9.37842 4.74756 7.5835 4.74756C5.78857 4.74756 4.3335 6.20428 4.3335 8.00124C4.3335 9.7982 5.78857 11.2549 7.5835 11.2549Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>