<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.47 10.9322C17.16 10.9322 18.2 8.45945 18.2 6.89768C18.2 5.59621 17.55 4.03444 16.25 4.03444C14.56 4.03444 13.52 6.50724 13.52 8.06901C13.52 9.37048 14.17 10.9322 15.47 10.9322ZM10.53 10.9322C11.83 10.9322 12.48 9.37048 12.48 8.06901C12.48 6.37709 11.44 3.9043 9.75004 3.9043C8.45004 3.9043 7.80004 5.46606 7.80004 6.76754C7.67004 8.45945 8.71004 10.9322 10.53 10.9322ZM20.15 9.63077C18.46 9.63077 17.29 11.9734 17.29 13.6653C17.29 14.8367 17.81 16.008 18.98 16.008C20.67 16.008 21.84 13.6653 21.84 11.9734C21.84 10.8021 21.19 9.63077 20.15 9.63077ZM8.84004 13.6653C8.84004 11.9734 7.54004 9.63077 5.98004 9.63077C4.81004 9.63077 4.29004 10.8021 4.29004 11.9734C4.29004 13.6653 5.59004 16.008 7.15004 16.008C8.32004 16.008 8.84004 14.8367 8.84004 13.6653ZM13 13.405C10.4 13.405 6.89004 17.5698 6.89004 20.433C6.89004 21.7345 7.80004 22.1249 8.84004 22.1249C10.4 22.1249 11.57 21.0837 13 21.0837C14.3 21.0837 15.47 22.1249 16.9 22.1249C17.94 22.1249 19.11 21.8646 19.11 20.433C19.11 17.5698 15.6 13.405 13 13.405Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>