<template>
  <ul class="mt-12" style="overflow-y: auto">
    <!-- <NavEach
      :title="$t('account_dropdown_menu_settings')"
      component="SettingIcon"
      to="Account"
      :data="user"
    /> -->
    <NavEach
      :title="$t('pet_record_title')"
      component="PawsIcon"
      to="PetOverview"
    />
    <!-- <NavEach
      :title="$t('pet_dropdown_menu_health_log')"
      component="HealthIcon"
      to="HealthLogOverview"
    /> -->
    <NavEach
      :title="$t('pet_dropdown_menu_diary_routine')"
      component="DiaryIcon"
      to="PetownerDiaryDashboard"
    />
    <!--  <NavEach
      :title="$t('pet_dropdown_menu_breeder_tool')"
      component="LitterRecordsIcon"
      to=""
    /> -->
    <!--  <NavEach
      :title="$t('community_section_title')"
      component="CommunityIcon"
      to="CommunityOverview"
    /> -->
    <!--  <NavEach
      :title="$t('global_header_services')"
      component="ServiceIcon"
      to="BrowseServiceDirectory"
    /> -->
    <!--  <NavEach
      :title="$t('expenses_section_title')"
      component="ExpenseIcon"
      to=""
    /> -->
    <!-- <NavEach :title="$t('contacts_section_title')" component="ContactIcon" to="" /> -->

    <!-- <NavEach
      :title="$t('insurance_tool_coming_soon_section_title')"
      component="VetIcon"
      to=""
    /> -->
    <!--  <NavEach
      :title="$t('cost_tool_coming_soon_section_title')"
      component="DatabaseIcon"
      to=""
    /> -->
    <!--  <NavEach
      :title="$t('referral_program_section_title')"
      component="UserIcon"
      to=""
    /> -->
    <div v-if="routeName == 'OnboardingDashboard'"></div>
    <div v-else>
      <SubmitButtonVue
        title="Finish Onboarding"
        width="w-max"
        class="m-auto"
        @click.native="$router.push('/onboardingdashboard')"
        v-if="progress != '100'"
      />
    </div>
  </ul>
</template>

<script>
import NavEach from "./NavEach.vue";
import NavComp from "./NavComp.vue";
import ModalsLogout from "../modals/ModalsLogout.vue";
import SubmitButtonVue from "../buttons/SubmitButton.vue";

import { getProgress } from "../../services/commonService";

export default {
  props: { user: {} },
  components: {
    NavComp,
    NavEach,
    ModalsLogout,
    SubmitButtonVue,
  },
  data() {
    return {
      progress: "",
      routeName: "",
    };
  },
  async created() {
    const { data: progress } = await getProgress();
    //console.log(progress);
    this.progress = progress.progress;
    this.routeName = this.$route.name;
  },
};
</script>

<style></style>
