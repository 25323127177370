<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.5219 25.9984L33.1482 25.5466C31.4744 23.5203 30.4588 22.2966 30.4588 16.5587C30.4588 13.5882 29.7487 11.1508 28.3463 9.32263C27.3144 7.97062 25.9186 6.94688 24.0791 6.18963C24.0551 6.1767 24.0337 6.15965 24.0157 6.13925C23.3543 3.92275 21.5424 2.4375 19.4998 2.4375C17.4588 2.4375 15.6486 3.92275 14.9856 6.136C14.9678 6.15555 14.947 6.17202 14.9238 6.18475C10.6273 7.956 8.54082 11.3474 8.54082 16.5571C8.54082 22.2983 7.52845 23.5219 5.85145 25.545L5.4777 25.9984C5.15144 26.3924 4.94486 26.8715 4.88236 27.3792C4.81987 27.8869 4.9041 28.4019 5.12507 28.8632C5.5947 29.8545 6.5957 30.4688 7.7397 30.4688H31.2697C32.4072 30.4688 33.4017 29.8545 33.8729 28.8681C34.0946 28.4065 34.1794 27.891 34.1172 27.3827C34.055 26.8744 33.8484 26.3946 33.5219 26V25.9984Z"
      :fill="iconColor"
    />
    <path
      d="M19.4999 36.5625C20.6009 36.5619 21.6813 36.2632 22.6262 35.6982C23.5712 35.1331 24.3456 34.3228 24.8673 33.3531C24.904 33.2831 24.9121 33.2015 24.8896 33.1256C24.8671 33.0497 24.816 32.9856 24.747 32.9469C24.7015 32.9206 24.65 32.9066 24.5975 32.9063H14.4071C14.3546 32.9062 14.3028 32.9196 14.257 32.9454C14.2112 32.9711 14.1727 33.0082 14.1455 33.0532C14.1182 33.0981 14.1029 33.1493 14.1012 33.2019C14.0995 33.2544 14.1114 33.3065 14.1358 33.3531C14.6572 34.3225 15.4314 35.1327 16.3761 35.6977C17.3207 36.2628 18.4007 36.5616 19.5015 36.5625H19.4999Z"
      :fill="iconColor"
    />
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 39,
    },
    height: {
      type: [Number, String],
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#F89520",
    },
  },
};
</script>