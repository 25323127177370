t<template>
    <router-link :to="{ name: to, params: { data: data } }">
      <li
        class="
          py-3
          px-8
          font-proximaMedium
          text-left
          cursor-pointer
          border-transparent border-r-4
          flex
          items-center
        "
      >
        <component
          :is="component"
          width="20"
          height="20"
          :iconColor="hoverComp"
        />
        <span class="ml-6 text-lg" style="color : #FFAA54;">{{ title }}</span>
      </li>
    </router-link>
  </template>
  
  <script>
  import SettingIcon from "../icons/SettingIcon.vue";
  import PawsIcon from "../icons/PawsIcon.vue";
  import ServiceIcon from "../icons/ServiceIcon.vue";
  import CommunityIcon from "../icons/CommunityIcon.vue";
  import ContactIcon from "../icons/ContactIcon.vue";
  import DiaryIcon from "../icons/DiaryIcon.vue";
  import ExpenseIcon from "../icons/ExpenseIcon.vue";
  import VetIcon from "../icons/VetIcon.vue";
  import DatabaseIcon from "../icons/DatabaseIcon.vue";
  import LitterRecordsIcon from "../icons/LitterRecordsIcon.vue";
  import HealthIcon from "../icons/HealthIcon.vue";
  import UserIcon from "../icons/UserIcon.vue";
  import SubscriptionIcon from "../icons/SubscriptionIcon.vue";
  import PuppyIcon from "../icons/PuppyIcon.vue";
  import UpgradeIcon from "../icons/UpgradeIcon.vue";
  import SignoutIcon from "../icons/SignoutIcon.vue";
  
  export default {
    props: { title: String, component: String, data: {}, to: String },
    components: {
      SettingIcon,
      PawsIcon,
      CommunityIcon,
      ContactIcon,
      DiaryIcon,
      ExpenseIcon,
      ServiceIcon,
      VetIcon,
      DatabaseIcon,
      UserIcon,
      SubscriptionIcon,
      PuppyIcon,
      LitterRecordsIcon,
      HealthIcon,
      SignoutIcon,
      UpgradeIcon
    },
    data() {
      return {
        hover: false,
      };
    },
    computed: {
      hoverComp() {
        if (this.hover) {
          return "#fff";
        } else {
          return "#FFAA54";
        }
      },
    },
  };
  </script>