<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 33 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 3.59009C9.108 3.59009 3.09375 9.9061 3.09375 17.669C3.09375 25.4319 9.108 31.7479 16.5 31.7479C23.892 31.7479 29.9062 25.4319 29.9062 17.669C29.9062 9.9061 23.892 3.59009 16.5 3.59009ZM20.966 10.0996C22.2052 10.1013 23.3929 10.6199 24.268 11.5413C25.143 12.4627 25.6337 13.7114 25.6321 15.0128C25.6304 16.3141 25.1366 17.5615 24.2592 18.4804C23.3818 19.3994 22.1928 19.9147 20.9536 19.913C19.7145 19.911 18.5268 19.3923 17.6519 18.4707C16.7769 17.5492 16.2864 16.3004 16.2882 14.9991C16.2901 13.6977 16.7841 12.4505 17.6616 11.5316C18.5391 10.6128 19.7282 10.0977 20.9674 10.0996H20.966ZM10.9698 11.8844C11.4717 11.885 11.9687 11.9895 12.4322 12.1919C12.8957 12.3942 13.3168 12.6905 13.6713 13.0637C14.0258 13.4369 14.3068 13.8798 14.4983 14.3671C14.6898 14.8544 14.7881 15.3766 14.7874 15.9037C14.7868 16.4309 14.6873 16.9528 14.4946 17.4395C14.3019 17.9263 14.0198 18.3685 13.6645 18.7408C13.3091 19.1131 12.8873 19.4082 12.4233 19.6093C11.9593 19.8104 11.4621 19.9136 10.9601 19.913C9.94634 19.9116 8.97457 19.4874 8.25862 18.7336C7.54266 17.9799 7.14116 16.9583 7.14244 15.8936C7.14371 14.829 7.54766 13.8084 8.26542 13.0566C8.98319 12.3047 9.95596 11.883 10.9698 11.8844ZM6.08162 22.375C7.66012 21.4754 9.68275 21.0263 10.9698 21.0263C11.9364 21.0263 13.1821 21.2284 14.4072 21.7165C13.5858 22.1974 12.8454 22.817 12.2155 23.5504C11.3657 24.5669 10.8776 25.7799 10.8776 26.9914V28.0065C8.74329 26.7226 7.06108 24.7488 6.08162 22.3793V22.375ZM16.5 29.5819C15.1951 29.583 13.8999 29.3466 12.672 28.883V27.052C12.672 23.4926 18.1995 21.6977 20.966 21.6977C22.4496 21.6977 24.7294 22.2147 26.5238 23.2428C25.5583 25.1555 24.1153 26.7561 22.3494 27.8729C20.5835 28.9897 18.5614 29.5805 16.5 29.5819Z"
      :fill="iconColor"
    />
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>