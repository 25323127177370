<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 29 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_34310_9717)">
      <path
        d="M27.7086 29.4854C27.3279 29.8675 26.728 29.8652 26.3484 29.4818L20.714 23.5665C21.2044 23.1274 21.661 22.6482 22.081 22.1344L27.7109 28.0518C27.8916 28.2415 27.9933 28.5003 27.9921 28.7698C27.9921 29.038 27.8893 29.2953 27.7086 29.4854ZM0.996976 13.5779C0.996976 10.3566 2.21617 7.26541 4.38651 4.98722C6.55572 2.70786 9.49905 1.42741 12.5666 1.42741C15.6341 1.42741 18.5783 2.70786 20.7475 4.98722C22.9167 7.26541 24.1359 10.3566 24.1359 13.5779C24.1359 16.7993 22.9167 19.8916 20.7475 22.1698C18.5783 24.448 15.6349 25.7284 12.5666 25.7284C9.49933 25.7249 6.55909 24.4433 4.38988 22.1663C2.22067 19.8881 1.00035 16.7993 0.997257 13.5779H0.996976ZM22.6838 21.3359C25.1932 17.7285 25.7906 13.032 24.2733 8.85778C22.7563 4.68474 19.3282 1.603 15.1685 0.671656C11.0085 -0.260865 6.68104 1.08332 3.67365 4.24327C0.667098 7.40293 -0.611669 11.9463 0.277371 16.3156C1.16641 20.6846 4.10187 24.2833 8.0767 25.876C12.0501 27.4684 16.522 26.8383 19.9568 24.2016L25.6645 30.1948H25.6633C26.0205 30.5947 26.5164 30.826 27.0382 30.8355C27.5603 30.8449 28.0641 30.6313 28.4336 30.2435C28.8031 29.8566 29.0065 29.3287 28.9995 28.7804C28.9916 28.2321 28.7724 27.7112 28.3917 27.3353L22.6838 21.3359Z"
        fill="#0AC0CB"
      />
      <path
        d="M12.567 23.7043C10.1786 23.7008 7.87591 22.7683 6.1043 21.0868C4.33268 19.4041 3.21745 17.0917 2.97468 14.5963L4.86374 13.1783L5.83202 13.8559L5.8309 13.8571C5.92025 16.3203 7.2229 18.5583 9.27016 19.7638C11.3174 20.969 13.8154 20.9693 15.8627 19.7638C17.9099 18.5583 19.2126 16.3203 19.3019 13.8571L20.2702 13.1795L22.1593 14.5975L22.1581 14.5963C21.9151 17.0917 20.8001 19.4032 19.0296 21.0856C17.258 22.7683 14.9553 23.7008 12.567 23.7043ZM12.567 7.50266C14.1012 7.50266 15.5735 8.14332 16.6581 9.28242C17.7427 10.4215 18.3516 11.9664 18.3516 13.5779C18.3516 15.1895 17.7427 16.7355 16.6581 17.8746C15.5735 19.0137 14.1014 19.6532 12.567 19.6532C11.0325 19.6532 9.56155 19.0137 8.47694 17.8746C7.39234 16.7355 6.78231 15.1895 6.78231 13.5779C6.78456 11.9678 7.39458 10.4227 8.47919 9.28478C9.56267 8.14568 11.0336 7.50502 12.567 7.50266ZM12.567 2.44078C12.3014 2.44078 12.0856 2.66741 12.0856 2.94629C12.0856 3.22516 12.3014 3.45297 12.567 3.45297C15.0899 3.45652 17.5112 4.49823 19.312 6.35412C21.1117 8.21002 22.1491 10.7337 22.1989 13.3823L20.5596 12.1541C20.3969 12.0319 20.1789 12.0283 20.0128 12.1446L19.2547 12.6749C18.9757 10.3764 17.6435 8.36642 15.689 7.29372C13.7333 6.22103 11.4014 6.22103 9.4455 7.29372C7.48984 8.36642 6.15768 10.3764 5.87979 12.6749L5.12169 12.1446C4.9545 12.0283 4.73758 12.0319 4.57377 12.1541L2.93449 13.3834H2.93562C2.97074 11.1504 3.71058 8.99321 5.03824 7.24887C6.36702 5.50334 8.20747 4.27041 10.2738 3.74247C10.5326 3.67607 10.6908 3.40192 10.6276 3.13013C10.5644 2.85834 10.3033 2.6922 10.0446 2.7586C6.5092 3.66633 3.66337 6.41314 2.50543 10.0358C1.34636 13.6597 2.03759 17.6536 4.33465 20.6179C6.63283 23.5819 10.2165 25.1034 13.8297 24.6463C17.4429 24.1907 20.5815 21.821 22.1452 18.3704C23.7078 14.9186 23.4785 10.8665 21.5352 7.63398C19.5919 4.40173 16.2069 2.44137 12.5678 2.44019L12.567 2.44078Z"
        fill="#0AC0CB"
      />
      <path
        d="M11.1184 11.5537C11.1217 10.9118 11.507 10.3411 12.0833 10.1275V12.9787C11.507 12.7651 11.1217 12.1944 11.1184 11.5537ZM14.0108 15.6034C14.0086 16.244 13.6234 16.8148 13.0471 17.0284V14.1783C13.6234 14.392 14.0086 14.9627 14.0108 15.6034ZM11.1184 15.6034C11.1184 15.3233 10.9026 15.0967 10.637 15.0967C10.3715 15.0967 10.1546 15.3233 10.1546 15.6034C10.1557 16.8053 10.9613 17.8426 12.0833 18.0846V18.1345C12.0833 18.4145 12.2991 18.6412 12.5646 18.6412C12.8301 18.6412 13.0471 18.4145 13.0471 18.1345V18.0846C14.1702 17.845 14.978 16.808 14.978 15.6034C14.978 14.3988 14.1702 13.3618 13.0471 13.1222V10.1272C13.6234 10.3408 14.0086 10.9116 14.0108 11.5534C14.0108 11.8323 14.2266 12.0589 14.4933 12.0589C14.76 12.0589 14.9757 11.8323 14.9757 11.5534C14.9735 10.3503 14.169 9.31417 13.0471 9.07219V9.02113C13.0471 8.74226 12.8313 8.51562 12.5646 8.51562C12.298 8.51562 12.0833 8.74226 12.0833 9.02113V9.07219C10.9591 9.31181 10.1523 10.3488 10.1523 11.5534C10.1523 12.758 10.9591 13.7938 12.0833 14.0334V17.0296C11.507 16.8159 11.1206 16.2452 11.1184 15.6034Z"
        fill="#0AC0CB"
      />
    </g>
    <defs>
      <clipPath id="clip0_34310_9717">
        <rect
          width="29"
          height="30.4551"
          fill="white"
          transform="translate(0 0.380859)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 29,
    },
    height: {
      type: [Number, String],
      default: 31,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>