<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 37 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_38278_25427)">
      <g clip-path="url(#clip1_38278_25427)">
        <path
          d="M15.6058 28.3074C17.1446 27.6088 17.0694 24.9273 16.4238 23.5052C15.8858 22.3201 14.6484 21.1667 13.4646 21.7041C11.9258 22.4027 12.001 25.0843 12.6466 26.5063C13.1846 27.6914 14.422 28.8448 15.6058 28.3074ZM11.1076 30.3495C12.2913 29.8121 12.2376 28.1213 11.6996 26.9362C11.0002 25.3957 9.03102 23.5739 7.49218 24.2725C6.30844 24.8099 6.36217 26.5007 6.90017 27.6858C7.48118 29.2801 9.45036 31.1018 11.1076 30.3495ZM19.3292 25.1878C17.7903 25.8864 17.6934 28.5031 18.3928 30.0437C18.877 31.1103 19.8346 31.9619 20.9 31.4783C22.4389 30.7797 22.5358 28.1629 21.8364 26.6223C21.3522 25.5557 20.2762 24.7579 19.3292 25.1878ZM10.6985 33.5367C9.99912 31.9961 7.847 30.4004 6.42653 31.0453C5.36117 31.5289 5.37187 32.8104 5.85607 33.877C6.55546 35.4176 8.70758 37.0133 10.1281 36.3685C11.1934 35.8848 11.1827 34.6033 10.6985 33.5367ZM14.3789 31.5801C12.0114 32.6549 10.5369 37.898 11.7205 40.5052C12.2585 41.6902 13.2485 41.6696 14.1955 41.2397C15.6159 40.5948 16.2509 39.1631 17.553 38.572C18.7367 38.0346 20.2325 38.499 21.5346 37.9079C22.4816 37.478 23.4393 36.7573 22.8476 35.4538C21.664 32.8466 16.7463 30.5053 14.3789 31.5801Z"
          :fill="iconColor"
        />
      </g>
      <g clip-path="url(#clip2_38278_25427)">
        <path
          d="M28.5263 9.35438C30.1251 9.90189 31.9102 7.89938 32.4161 6.42184C32.8378 5.19056 32.7288 3.50244 31.4989 3.08129C29.9 2.53378 28.115 4.53629 27.609 6.01382C27.1874 7.2451 27.2964 8.93322 28.5263 9.35438ZM23.8527 7.75398C25.0826 8.17514 26.2035 6.90818 26.6251 5.6769C27.1733 4.07623 26.9905 1.39987 25.3916 0.852363C24.1617 0.431204 23.0408 1.69816 22.6192 2.92944C21.9481 4.48799 22.1309 7.16435 23.8527 7.75398ZM33.3755 9.63927C31.7767 9.09177 29.9108 10.929 29.3627 12.5297C28.9832 13.6378 29.0957 14.9145 30.2026 15.2935C31.8015 15.841 33.6673 14.0037 34.2154 12.4031C34.5949 11.2949 34.3594 9.9762 33.3755 9.63927ZM21.3684 9.79216C21.9165 8.1915 21.4456 5.55403 19.9697 5.04864C18.8628 4.6696 17.9914 5.60929 17.6119 6.71744C17.0638 8.3181 17.5347 10.9556 19.0106 11.461C20.1175 11.84 20.9889 10.9003 21.3684 9.79216ZM25.3884 10.8936C22.9286 10.0513 18.2587 12.8543 17.3311 15.5631C16.9094 16.7944 17.6439 17.4586 18.6278 17.7955C20.1036 18.3009 21.5479 17.6949 22.9007 18.1582C24.1306 18.5793 24.9002 19.9434 26.2531 20.4067C27.237 20.7436 28.4282 20.8764 28.892 19.522C29.8196 16.8132 27.8482 11.7359 25.3884 10.8936Z"
          :fill="iconColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_38278_25427">
        <rect width="37" height="45" fill="white" />
      </clipPath>
      <clipPath id="clip1_38278_25427">
        <rect
          width="26"
          height="26.0294"
          fill="white"
          transform="translate(-3 24.7478) rotate(-24.4169)"
        />
      </clipPath>
      <clipPath id="clip2_38278_25427">
        <rect
          width="26"
          height="26.0294"
          fill="white"
          transform="translate(17.4326 -6) rotate(18.9031)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 37,
    },
    height: {
      type: [Number, String],
      default: 45,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>