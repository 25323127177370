<template>
  <router-link :to="{ name: to, params: { data: data } }">
    <li
      class="
        flex flex-col
        justify-center
        items-center
        py-3
        px-8
        font-proximaMedium
        text-center
        cursor-pointer
        border-transparent border-r-4
      "
    >
      <component
        :is="component"
        :width="width"
        :height="height"
        :iconColor="hoverComp"
      />
      <span class="ml-6 text-xl" v-if="title">{{ title }}</span>
    </li>
  </router-link>
</template>
    
    <script>
import SettingIcon from "../icons/SettingIcon.vue";
import PawsIcon from "../icons/PawsIcon.vue";
import BellIcon from "../icons/BellIcon.vue";
import ServiceIcon from "../icons/ServiceIcon.vue";
import CommunityIcon from "../icons/CommunityIcon.vue";
import ContactIcon from "../icons/ContactIcon.vue";
import DiaryIcon from "../icons/DiaryIcon.vue";
import ExpenseIcon from "../icons/ExpenseIcon.vue";
import VetIcon from "../icons/VetIcon.vue";
import DatabaseIcon from "../icons/DatabaseIcon.vue";
import LitterRecordsIcon from "../icons/LitterRecordsIcon.vue";
import HealthIcon from "../icons/HealthIcon.vue";
import UserIcon from "../icons/UserIcon.vue";
import SubscriptionIcon from "../icons/SubscriptionIcon.vue";
import PuppyIcon from "../icons/PuppyIcon.vue";
import SignoutIcon from "../icons/SignoutIcon.vue";
import UpgradeIcon from "../icons/UpgradeIcon.vue";

export default {
  props: {
    title: String,
    iconColor: String,
    component: String,
    data: {},
    to: String,
    width: Number,
    height: Number,
  },
  components: {
    SettingIcon,
    PawsIcon,
    BellIcon,
    CommunityIcon,
    ContactIcon,
    DiaryIcon,
    ExpenseIcon,
    ServiceIcon,
    VetIcon,
    DatabaseIcon,
    UserIcon,
    SubscriptionIcon,
    PuppyIcon,
    LitterRecordsIcon,
    HealthIcon,
    SignoutIcon,
    UpgradeIcon,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    hoverComp() {
      if (this.iconColor) {
        return this.iconColor;
      } else {
        return "#FFAA54";
      }
    },
  },
};
</script>