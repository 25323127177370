<template>
  <ul class="mt-12" style="overflow-y: auto">
    <!-- <NavEach
      :title="$t('account_dropdown_menu_settings')"
      component="SettingIcon"
      to="Account"
      :data="user"
    /> -->
    <NavEach
      :title="$t('pet_record_title')"
      component="PawsIcon"
      to="PetOverview"
    />

    <br />
    <br />
    <div v-if="routeName == 'OnboardingDashboard'"></div>
    <div v-else>
      <SubmitButtonVue
        title="Finish Onboarding"
        width="w-max"
        class="m-auto"
        @click.native="$router.push('/onboardingdashboard')"
        v-if="progress != '100'"
      />
    </div>
  </ul>
</template>

<script>
import NavEach from "./NavEach.vue";
import NavComp from "./NavComp.vue";
import ModalsLogout from "../modals/ModalsLogout.vue";
import SubmitButtonVue from "../buttons/SubmitButton.vue";

import { getProgress } from "../../services/commonService";

export default {
  props: { user: {} },
  components: {
    NavComp,
    NavEach,
    ModalsLogout,
    SubmitButtonVue,
  },
  data() {
    return {
      showModal: false,
      progress: "",
      routeName: "",
    };
  },
  async created() {
    const { data: progress } = await getProgress();
    //console.log(progress);
    this.progress = progress.progress;
    this.routeName = this.$route.name;
  },
};
</script>

<style></style>
