<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.4168 15.5H24.2502L20.3752 27.125L12.6252 3.875L8.75016 15.5H3.5835"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="16.5"
      cy="16.5"
      r="15.75"
      :stroke="iconColor"
      stroke-width="1.5"
    />
  </svg>
</template>
    
    <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 33,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>