<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.15625 5C4.45177 5 3.77614 5.27985 3.278 5.778C2.77985 6.27614 2.5 6.95177 2.5 7.65625V22.3438C2.5 23.0482 2.77985 23.7239 3.278 24.222C3.77614 24.7201 4.45177 25 5.15625 25H24.8438C25.5482 25 26.2239 24.7201 26.722 24.222C27.2201 23.7239 27.5 23.0482 27.5 22.3438V7.65625C27.5 6.95177 27.2201 6.27614 26.722 5.778C26.2239 5.27985 25.5482 5 24.8438 5H5.15625ZM11.875 12.5C11.875 12.9973 11.6775 13.4742 11.3258 13.8258C10.9742 14.1775 10.4973 14.375 10 14.375C9.50272 14.375 9.02581 14.1775 8.67417 13.8258C8.32254 13.4742 8.125 12.9973 8.125 12.5C8.125 12.0027 8.32254 11.5258 8.67417 11.1742C9.02581 10.8225 9.50272 10.625 10 10.625C10.4973 10.625 10.9742 10.8225 11.3258 11.1742C11.6775 11.5258 11.875 12.0027 11.875 12.5ZM6.875 16.5625C6.875 16.3139 6.97377 16.0754 7.14959 15.8996C7.3254 15.7238 7.56386 15.625 7.8125 15.625H12.1875C12.4361 15.625 12.6746 15.7238 12.8504 15.8996C13.0262 16.0754 13.125 16.3139 13.125 16.5625V16.875C13.125 16.875 13.125 19.375 10 19.375C6.875 19.375 6.875 16.875 6.875 16.875V16.5625ZM15.625 12.6562C15.625 12.225 15.975 11.875 16.4062 11.875H22.3438C22.4463 11.875 22.5479 11.8952 22.6427 11.9345C22.7375 11.9737 22.8236 12.0313 22.8962 12.1038C22.9687 12.1764 23.0263 12.2625 23.0655 12.3573C23.1048 12.4521 23.125 12.5537 23.125 12.6562C23.125 12.7588 23.1048 12.8604 23.0655 12.9552C23.0263 13.05 22.9687 13.1361 22.8962 13.2087C22.8236 13.2812 22.7375 13.3388 22.6427 13.378C22.5479 13.4173 22.4463 13.4375 22.3438 13.4375H16.4062C15.975 13.4375 15.625 13.0875 15.625 12.6562ZM16.4062 16.25H22.3438C22.551 16.25 22.7497 16.3323 22.8962 16.4788C23.0427 16.6253 23.125 16.824 23.125 17.0312C23.125 17.2385 23.0427 17.4372 22.8962 17.5837C22.7497 17.7302 22.551 17.8125 22.3438 17.8125H16.4062C16.199 17.8125 16.0003 17.7302 15.8538 17.5837C15.7073 17.4372 15.625 17.2385 15.625 17.0312C15.625 16.824 15.7073 16.6253 15.8538 16.4788C16.0003 16.3323 16.199 16.25 16.4062 16.25Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>